import { useSelector } from "react-redux";
import { WebchatStore } from "../store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum EngagementPhase {
  PreEngagementForm = "PreEngagementForm",
  MessagingCanvas = "MessagingCanvas",
  Loading = "Loading",
}

export type PreEngagementData = {
  email: string;
};

export type SessionState = {
  currentPhase: EngagementPhase;
  expanded: boolean;
  token?: string;
  conversationSid?: string;
  preEngagementData: PreEngagementData;
};

const initialState: SessionState = {
  currentPhase: EngagementPhase.Loading,
  expanded: false,
  preEngagementData: { email: "" },
};

export const sessionSlice = createSlice({
  name: "webchat-session",
  initialState,
  reducers: {
    startSession: (
      state,
      action: PayloadAction<
        Pick<SessionState, "token" | "conversationSid" | "currentPhase">
      >
    ) => {
      state.token = action.payload.token;
      state.conversationSid = action.payload.conversationSid;
      state.currentPhase = action.payload.currentPhase;
    },
    updateSessionData: (
      state,
      action: PayloadAction<Pick<SessionState, "token" | "conversationSid">>
    ) => {
      state.token = action.payload.token;
      state.conversationSid = action.payload.conversationSid;
    },
    changeExpandedStatus: (
      state,
      action: PayloadAction<Pick<SessionState, "expanded">>
    ) => {
      state.expanded = action.payload.expanded;
    },
    changeEngagementPhase: (
      state,
      action: PayloadAction<Pick<SessionState, "currentPhase">>
    ) => {
      state.currentPhase = action.payload.currentPhase;
    },
    updatePreEngagementData: (
      state,
      action: PayloadAction<{ preEngagementData: PreEngagementData }>
    ) => {
      state.preEngagementData.email = action.payload.preEngagementData.email;
    },
    updatePreEngagementDataEmail: (
      state,
      action: PayloadAction<{ preEngagementData: PreEngagementData }>
    ) => {
      state.preEngagementData.email = action.payload.preEngagementData.email;
    },
  },
});

export const {
  startSession,
  updateSessionData,
  changeExpandedStatus,
  changeEngagementPhase,
  updatePreEngagementData,
  updatePreEngagementDataEmail,
} = sessionSlice.actions;
export const sessionReducer = sessionSlice.reducer;

export const useSelectSessionState = () =>
  useSelector((state: WebchatStore) => ({
    currentPhase: state.session.currentPhase,
    expanded: state.session.expanded,
    preEngagementData: state.session.preEngagementData,
  }));
