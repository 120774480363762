import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import { WebchatStore } from "../store/store";
import log from "loglevel";
import { EngagementPhase } from "../store/features/sessionSlice";

function logEvent(
  eventName: string,
  params?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams
) {
  log.info("[GA] Event fired:", eventName, "\nattributes:", params ?? "None");
  gtag("event", eventName, params);
}

function conversationStateEvents(
  previous: WebchatStore,
  current: WebchatStore
) {
  const currentChatState = current.chat.conversationState;
  const previousChatState = previous.chat.conversationState;
  if (previousChatState === currentChatState) {
    return;
  }
  if (previousChatState === undefined) {
    return;
  }
  const params = {
    conversationSid: current.session.conversationSid,
    email:
      current.session.preEngagementData.email ??
      current.chat.conversation?.attributes.pre_engagement_data.email,
  };
  switch (currentChatState) {
    case "active":
      return logEvent("webchat_activated", params);
    case "inactive":
      return logEvent("webchat_inactive", params);
    default:
    case "closed":
      return logEvent("webchat_ended", params);
  }
}

function openCloseEvents(previous: WebchatStore, current: WebchatStore) {
  if (previous.session.expanded === current.session.expanded) {
    return;
  }
  logEvent(current.session.expanded ? "webchat_opened" : "webchat_closed");
}

function sessionStartedEvent(previous: WebchatStore, current: WebchatStore) {
  if (
    previous.session.currentPhase !== EngagementPhase.PreEngagementForm ||
    current.session.currentPhase !== EngagementPhase.Loading
  ) {
    return;
  }
  logEvent("webchat_started", {
    conversationSid: current.session.conversationSid,
    email: current.session.preEngagementData.email,
  });
}

export function addAnalyticEventListener(store: ToolkitStore<WebchatStore>) {
  let previousState = store.getState();
  store.subscribe(() => {
    const currentState = store.getState();
    conversationStateEvents(previousState, currentState);
    openCloseEvents(previousState, currentState);
    sessionStartedEvent(previousState, currentState);
    previousState = currentState;
  });
}
