import { useSelector } from "react-redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WebchatStore } from "../store";
import { AlertVariants } from "@twilio-paste/core/alert";

export type Notification = {
  dismissible: boolean;
  id: string;
  onDismiss?: () => void;
  message: string;
  timeout?: number;
  type: AlertVariants;
};

export type NotificationState = Notification[];

const initialState: NotificationState = [];

export const notificationSlice = createSlice({
  name: "webchat-notification",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      return state.concat(action.payload);
    },
    removeNotification: (state, action: PayloadAction<Notification["id"]>) => {
      return state.filter((notification) => notification.id !== action.payload);
    },
  },
});

export const { addNotification, removeNotification } =
  notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;

export const useSelectNotificationState = () =>
  useSelector((state: WebchatStore) => state.notifications);
