import { Global, css } from "@emotion/react";
import { theme } from "./configDataHandler";

const GlobalStyles = () => (
  <Global
    styles={css`
      body {
        background-color: ${theme?.backgroundColor || "white"};
      }
    `}
  />
);

export default GlobalStyles;
