import { Provider } from "react-redux";
// eslint-disable-next-line react/no-deprecated
import { render } from "react-dom";
import { initLogger } from "./logger";
import { getConfiguredStore } from "./store/store";
import { load as loadRecaptcha } from "recaptcha-v3";
import { recaptchaContext } from "./recaptchaContext";
import { addAnalyticEventListener } from "./utils/analyticEventListener";
import log from "loglevel";
import GlobalStyles from "./GlobalStyles";

const store = getConfiguredStore();

const initWebchat = async () => {
  initLogger();

  addAnalyticEventListener(store);
  const rootElement = document.getElementById("twilio-webchat-widget-root");

  const { WebchatWidget } = await import("./components/WebchatWidget");

  if (!process.env.REACT_APP_SITE_KEY) {
    log.error("No Recaptcha Key Provided");
    return;
  }
  const recaptcha = await loadRecaptcha(process.env.REACT_APP_SITE_KEY, {
    autoHideBadge: true,
  });

  render(
    <>
      <GlobalStyles />
      <Provider store={store}>
        <recaptchaContext.Provider value={{ recaptcha }}>
          <WebchatWidget />
        </recaptchaContext.Provider>
      </Provider>
    </>,
    rootElement
  );
};

declare global {
  interface Window {
    Twilio: {
      initWebchat: () => Promise<void>;
    };
  }
}

// Expose `initWebchat` function to window object
Object.assign(window, {
  Twilio: {
    initWebchat,
  },
});
