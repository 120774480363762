import { configureStore } from "@reduxjs/toolkit";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import { chatReducer, ChatState } from "./features/chatSlice";
import {
  notificationReducer,
  NotificationState,
} from "./features/notificationSlice";
import { sessionReducer, SessionState } from "./features/sessionSlice";

export type WebchatStore = {
  chat: ChatState;
  notifications: NotificationState;
  session: SessionState;
};

let store: ToolkitStore<WebchatStore>;

export const getConfiguredStore = (): ToolkitStore<WebchatStore> => {
  if (!store) {
    store = configureStore({
      reducer: {
        chat: chatReducer,
        notifications: notificationReducer,
        session: sessionReducer,
      },
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: {
            ignoredPaths: ["chat"],
            ignoredActions: [
              "webchat-chat/startChatSession",
              "webchat-chat/updateParticipant",
              "webchat-chat/addMessage",
              "webchat-chat/addParticipant",
            ],
          },
        }),
    });
  }

  return store;
};

export const getDispatch = () => store.dispatch;
