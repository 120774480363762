import { FileAttachmentConfig } from "./definitions";
import { ThemeFromSyncDoc } from "./utils/themeTransformer";

export const zingColorPalette = {
  primary: {
    grapeZing: "#8152F7",
    darkEclipse: "#141C3C",
    neutral: "#F2F2F2",
  },
  secondary: {
    limeZing: "#C1F43D",
    midnightBlue: "#392B6C",
    periwinkel: "#A581FF",
    violet: "#CBB6FF",
    pebble: "#F2E0C9",
  },
};

export const baseTheme = "default";

export const theme: ThemeFromSyncDoc = {
  infoColor: zingColorPalette.secondary.limeZing,
  errorColor: zingColorPalette.secondary.pebble,
  backgroundColor: zingColorPalette.primary.neutral,
  primaryColor: zingColorPalette.primary.grapeZing,
  secondaryColor: zingColorPalette.secondary.violet,
  successColor: zingColorPalette.secondary.periwinkel,
  textColor: zingColorPalette.primary.darkEclipse,
  warningColor: zingColorPalette.secondary.midnightBlue,
};

export const fileAttachmentConfig: FileAttachmentConfig = {
  enabled: true,
  maxFileSize: 16777216, // 16 MB
  acceptedExtensions: ["jpg", "jpeg", "png", "amr", "mp3", "mp4", "pdf", "txt"],
};
